import { RouteObject } from 'react-router-dom'
import { SalesPage } from './sales'
import { HourlySalesPage } from './hourly/hourly'

export const SalesRoute: RouteObject = {
  path: '/sales',
  element: <SalesPage />,
  index: true,
}

export const SalesByLocationRoute: RouteObject = {
  path: '/sales/location/:locationID',
  element: <SalesPage />,
}

export const SalesHourlyRoute: RouteObject = {
  path: '/sales/hourly',
  element: <HourlySalesPage />,
}
