import { useMemo, useState } from 'react'
import {
  Cell,
  DataTableObjectType,
  addLinkToFirstColumn,
  buildDataTableObject,
  insertRows,
} from 'utils/data'
import {
  usePayrollLocationDaily,
  usePayrollLocationWeekly,
} from 'hooks/payroll/location'
import { BasicPlaceholder } from 'components/placeholder'
import { isStore } from 'utils/location'
import {
  getDynamicSubPath,
  getLocationTableIndexes,
  pickData,
} from '../helpers'
import { getTableFormat } from 'utils/table'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { ErrorAlert } from 'components/error-fallback'
import { useEssentials } from 'hooks/utils/useEssentials'

interface IStoreLocation {
  currentLocation: string
  isLoading: boolean
  isError: boolean
  bottomLeftCell: string
  error: Error
  count: number
}

export const StoreLocation = ({
  isLoading,
  isError,
  currentLocation,
  bottomLeftCell,
  error,
  count,
}: IStoreLocation) => {
  const [currentDate, setCurrentDate] = useState<string>('')
  const { env, session } = useEssentials()
  const dynamicSubPath = getDynamicSubPath(currentLocation)

  /* Store-level Data */
  let { data: weeklyData, isError: isWeeklyError } = usePayrollLocationWeekly({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath,
  })
  if (weeklyData) {
    weeklyData.rows = addLinkToFirstColumn(weeklyData.rows)
  }

  const widths = useMemo(
    () => [100, ...getTableFormat(weeklyData?.rows?.length ?? 20, 85)],
    [weeklyData?.rows?.length],
  )

  /* Store-level Drill Data */
  let { data: dailyData } = usePayrollLocationDaily({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath,
    current_date: currentDate,
  })

  if (isLoading || !weeklyData) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }
  if (isError || isWeeklyError) {
    return <ErrorAlert error={error as Error} count={count} />
  }

  const handleClick = (clickedDate: string) => {
    const matchedObject = weeklyData?.rows.find(
      (row: Cell[]) => row[0].value === clickedDate,
    )
    const matchedDate = matchedObject?.[3].value
    if (matchedDate === currentDate) {
      setCurrentDate('')
    } else if (matchedDate) {
      setCurrentDate(matchedDate)
    }
  }

  if (weeklyData) {
    weeklyData.rows = weeklyData.rows.filter((row) => !row[0].childOf)
    if (dailyData && weeklyData) {
      const updatedWeeklyData = { ...weeklyData }
      const { rows } = updatedWeeklyData
      const updatedDailyData = { ...dailyData }
      if (updatedDailyData.rows) {
        updatedDailyData.rows = updatedDailyData.rows.map((row: Cell[]) => {
          row[0].childOf = currentDate
          row[0].link = undefined
          return row
        })
        const insertIndex =
          rows.findIndex((row) => row[3].value === currentDate) + 1
        insertRows(updatedWeeklyData.rows, insertIndex, updatedDailyData.rows)
        weeklyData = updatedWeeklyData
      }
    }
  }

  const { startIndex: tableStartIndex, endIndex: tableEndIndex } =
    getLocationTableIndexes(currentLocation)

  const objParams = {
    currentData: pickData(
      weeklyData,
      currentLocation,
      tableStartIndex,
      tableEndIndex,
      'week #',
    ),
    widths,
    bottomLeftCell,
    pinFirstColumn: true,
  } as DataTableObjectType
  if (isStore(currentLocation)) {
    objParams.handleClick = handleClick
  }
  const obj = buildDataTableObject(objParams)

  return <Table data={obj} scrollableWidth={true} />
}
