import { useEffect } from 'react'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { Constants } from '../constants/constants'
import { Navigate } from 'react-router-dom'
import { useEssentials } from 'hooks/utils/useEssentials'

export const HomePage = () => {
  const { isAuthenticated, login } = useEssentials()

  useEffect(() => {
    if (isAuthenticated()) {
      logEvent(
        {
          message: `App - ${Constants.LOGIN_SUCCESSFUL}`,
        },
        { level: LogLevel.Info },
      )
    } else {
      logEvent(
        {
          message: `App - ${Constants.LOGIN_FAIL}`,
        },
        { level: LogLevel.Info },
      )
      login()
    }
  }, [isAuthenticated, login])

  if (isAuthenticated()) {
    return <Navigate to="/sales" />
  }

  return <h1>Redirecting to login</h1>
}
