import { Container } from 'components/container/Container'
import { SalesAreaOfStore } from './area-of-store'
import { SalesByMerchandise } from './sales-by-merchandise'

export const SalesDetails = () => {
  return (
    <Container>
      <SalesAreaOfStore />
      <SalesByMerchandise />
    </Container>
  )
}
