import { useEssentials } from 'hooks/utils/useEssentials'
import { isStore } from 'utils/location'
import { TheftStore } from './theft-store'

export const Theft = () => {
  const { currentLocation } = useEssentials()
  const isStoreLocation = isStore(currentLocation)
  if (isStoreLocation) {
    return <TheftStore />
  }

  return null
}
