import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchUserDetails } from 'queries/getUserDetailsQuery'
import { UserDetailsFetchProps } from 'types/api'
import { useAnalytics } from '@praxis/component-analytics'

export function useUserDetails({
  env,
  accessToken,
  lanId,
}: UserDetailsFetchProps) {
  const { trackEvent } = useAnalytics()
  const { data, isLoading, isError } = useQuery([QueryKeys.USER_DETAILS], () =>
    fetchUserDetails({
      env,
      accessToken,
      filters: {
        lan_id: lanId,
      },
      trackEvent,
    }),
  )

  return { data, isLoading, isError }
}
