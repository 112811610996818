import { Layout } from 'components/layout'
import { useNavigate } from 'react-router-dom'
import { clearLocationParam } from 'utils/helpers'
import { useStoreFacts } from 'hooks/store-facts/useStoreFacts'
import { BasicPlaceholder } from 'components/placeholder'
import { useStoreLeadership } from 'hooks/store-facts/useStoreLeadership'
import { formatStoreTime, mapFieldNames } from './helpers'
import {
  Anchor,
  Divider,
  ExpandableSection,
  Grid,
} from '@enterprise-ui/canvas-ui-react'
import { isStore } from 'utils/location'
import EnterpriseIcon, { ProcessingIcon, StoreIcon } from '@enterprise-ui/icons'
import { formatCurrency } from 'pages/sales/helpers'
import { useDeviceInfo } from 'hooks/utils/useDeviceInfo'
import { format, parseISO } from 'date-fns'
import { ErrorAlert } from 'components/error-fallback'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const StoreFactsPage = () => {
  const { t, params, setParams, currentLocation } = useEssentials()

  window.onpopstate = (e) => {
    clearLocationParam({ params, setParams })
  }

  return (
    <Layout heading={t('store facts')} className="">
      <StoreFacts key={currentLocation} />
    </Layout>
  )
}

const StoreFacts = () => {
  const navigate = useNavigate()
  const { contact, location: locationKeys, storeFacts } = TRANSLATION_KEYS
  const { t, currentLocation, env, session, userAgent } = useEssentials()

  const { data, isLoading, isError, error } = useStoreFacts({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
  })
  const { data: leadershipData, isLoading: leadershipLoading } =
    useStoreLeadership({
      env,
      accessToken: session?.accessToken ?? '',
      location: currentLocation,
    })
  const { isAppleDevice } = useDeviceInfo(userAgent)

  if (!isStore(currentLocation)) {
    navigate('/sales')
  }

  if (isLoading || leadershipLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }
  if (isError || !data) {
    return <ErrorAlert error={error as Error} />
  }

  const { basic, capabilities, locationDetails } = data[0]
  const {
    addressDetails,
    contactDetails,
    region_group_district,
    regional_distribution_center,
    last_year_sales,
    operating_hours,
    dates,
  } = basic
  const { address, city, region, pincode, country, latitude, longitude } =
    addressDetails
  const { contact_main, contact_fax } = contactDetails
  const { region_id, group_id, district_id } = region_group_district
  const { originated_sales, fulfilled_sales, total_sales } = last_year_sales
  const { time_zone_code, regular_hours } = operating_hours
  const { days } = regular_hours
  const { open_date, last_remodel_date } = dates

  const { store_type } = locationDetails

  const { card_config, query_results } = leadershipData as any
  const { card_query_attribute } = card_config
  const { columns } = card_query_attribute
  const mappedLeadership = mapFieldNames(columns, query_results)
  const mappedLeadershipField = mappedLeadership.filter(
    (l) => l.leader_type === 'field',
  )
  const mappedLeadershipStore = mappedLeadership.filter(
    (l) => l.leader_type === 'store',
  )

  const formattedFullDate = (dateStr: string) =>
    format(parseISO(dateStr), 'MMMM d, yyyy')

  const formattedShortDate = (dateStr: string) =>
    format(parseISO(dateStr), 'MM/dd/yy')

  let packStation = null
  if (basic.pack_stations) {
    packStation = {
      label: 'pack station',
      value: basic.pack_stations,
    }
  }

  const baseMapUrl = isAppleDevice
    ? 'http://maps.apple.com/?q='
    : 'https://www.google.com/maps/search/?api=1&query='
  const mapUrl = baseMapUrl + `${latitude},${longitude}`

  return (
    <>
      <StoreFactLine
        value={
          <span className="sf-org">
            <EnterpriseIcon icon={StoreIcon} />
            <span className="sf-org-name">
              <span>{t(regional_distribution_center)}</span>
              <span>{t(store_type)}</span>
            </span>
          </span>
        }
      />
      <br />
      <StoreFactHeading value={t(storeFacts.quickFacts)} />
      <StoreFactLine
        value={
          <Anchor href={mapUrl}>
            {`${address}`}
            <br />
            {`${city}, ${region} ${pincode}`}
          </Anchor>
        }
      />
      <StoreFactLine value={`${country} ${t(locationKeys.county)}`} />
      <br />
      <StoreFactLine
        value={
          <>
            <span>{t(contact.mainPhone)}: </span>
            <StoreFactPhone value={contact_main} />
          </>
        }
      />
      <StoreFactLine
        value={
          <>
            <span>{t(contact.fax)}: </span>
            <StoreFactPhone value={contact_fax} />
          </>
        }
      />
      <br />
      <StoreFactLine value={`${t(locationKeys.region)} ${region_id}`} />
      <StoreFactLine value={`${t(locationKeys.group)} ${group_id}`} />
      <StoreFactLine value={`${t(locationKeys.district)} ${district_id}`} />
      <StoreFactLine
        value={`${t(locationKeys.RDC)} ${regional_distribution_center}`}
      />
      <br />
      <StoreFactHeading value={t(storeFacts.lastYearSales)} />
      <StoreFactLine
        value={`${t(storeFacts.originatedSales)}: ${formatCurrency(originated_sales)}`}
      />
      <StoreFactLine
        value={`${t(storeFacts.fulfilledSales)}: ${formatCurrency(fulfilled_sales)}`}
      />
      <StoreFactLine
        value={`${t(storeFacts.totalSales)}: ${formatCurrency(total_sales)}`}
      />
      <br />
      <StoreFactHeading value={`${t(storeFacts.hours)} ${t(time_zone_code)}`} />
      <div>
        {days.map((d: any, index: number) => {
          const dayLabel =
            index === 0 || d.day !== days[index - 1].day
              ? `${t(`${d.day.toLocaleLowerCase()}`)}:`
              : ''
          const openTime = new Date(
            `1970-01-01T${d.begin_time}`,
          ).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })
          const closeTime = new Date(
            `1970-01-01T${d.end_time === '23:59:59' ? '22:00:00' : d.end_time}`,
          ).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })
          return (
            <StoreFactHours
              day={dayLabel}
              value={`${openTime} to ${closeTime}`}
              key={d.day}
            />
          )
        })}
      </div>
      <br />
      <StoreFactHeading value={t(storeFacts.keyDates)} />
      <StoreFactLine
        value={`${t(storeFacts.opened)} ${formattedFullDate(open_date)}`}
      />
      <StoreFactLine
        value={`${t(storeFacts.lastRemodel)} ${formattedFullDate(last_remodel_date)}`}
      />
      <br />
      <StoreFactExpandable value={t(storeFacts.leadership)}>
        {leadershipLoading ? (
          <EnterpriseIcon icon={ProcessingIcon} />
        ) : (
          <>
            <StoreFactSubheading value={t(storeFacts.store)} />
            <Grid.Container>
              <Grid.Item xs={4} className="hc-pv-min hc-pr-none">
                {t(storeFacts.role)}
              </Grid.Item>
              <Grid.Item xs={4} className="hc-pv-min hc-ph-none">
                {t(storeFacts.name)}
              </Grid.Item>
              <Grid.Item xs={4} className="hc-pv-min hc-pl-none">
                {t(storeFacts.since)}
              </Grid.Item>
            </Grid.Container>
            <>
              {mappedLeadershipStore.map((l, i) => {
                return (
                  <Grid.Container key={l.employee_name}>
                    <Grid.Item xs={4} className="hc-pv-min hc-pr-none">
                      {l.employee_job_title}
                    </Grid.Item>
                    <Grid.Item xs={4} className="hc-pv-min hc-ph-none">
                      <Anchor href={`mailto:${l.email_address}`}>
                        {l.employee_name}
                      </Anchor>
                    </Grid.Item>
                    <Grid.Item xs={4} className="hc-pv-min hc-pl-none">
                      {formattedShortDate(l.position_start_date)}
                    </Grid.Item>
                  </Grid.Container>
                )
              })}
            </>
            <br />
            <StoreFactSubheading value={t('field')} />
            <Grid.Container>
              <Grid.Item xs={2} className="hc-pv-min hc-pr-none">
                {t(storeFacts.role)}
              </Grid.Item>
              <Grid.Item xs={10} className="hc-pv-min hc-pl-none">
                {t(storeFacts.name)}
              </Grid.Item>
            </Grid.Container>
            <>
              {mappedLeadershipField.map((l, i) => {
                return (
                  <Grid.Container key={l.employee_name}>
                    <Grid.Item xs={2} className="hc-pv-min hc-pr-none">
                      {l.employee_job_title}
                    </Grid.Item>
                    <Grid.Item xs={10} className="hc-pv-min hc-pl-none">
                      <Anchor href={`mailto:${l.email_address}`}>
                        {l.employee_name}
                      </Anchor>
                    </Grid.Item>
                  </Grid.Container>
                )
              })}
            </>
          </>
        )}
      </StoreFactExpandable>
      <br />
      <StoreFactHeading value={t('capabilities')} />
      {packStation && (
        <>
          <p className="hc-mv-sm flex-sb">
            <span>{packStation.label}</span>
            <span>{packStation.value}</span>
          </p>
          <Divider />
        </>
      )}
      <>
        {capabilities.map((c: any, i: number) => {
          const isNotFirst = i > 0
          return (
            <div key={i}>
              {isNotFirst && <Divider />}
              <StoreFactCapability key={i} {...c} />
            </div>
          )
        })}
      </>
    </>
  )
}

type StoreFaceLineType = {
  value: string | React.ReactNode
}
type StoreFaceHourType = {
  day: string
  value: string | React.ReactNode
}
type StoreFaceExpandedType = StoreFaceLineType & {
  children: React.ReactNode | React.ReactNode[]
}

type DayOperatingHours = {
  day: string
  begin_time: string | null
  end_time: string | null
  is_open: boolean
  open_flag: boolean
  close_flag: boolean
}
type OperatingHours = DayOperatingHours[]
type ContactDetails = {
  contact_main: string
  contact_fax: string
}
type StoreFaceCapabilityType = {
  capability_name: string
  contactDetails?: ContactDetails
  capability_hours?: {
    effective_date: string
    expiration_date: string
    days: OperatingHours
  }
  timezone: string
}
type StoreFaceCapabilityLineType = StoreFaceLineType & {
  className: string
}
const StoreFactHeading = ({ value }: StoreFaceLineType) => <h1>{value}</h1>
const StoreFactSubheading = ({ value }: StoreFaceLineType) => <h2>{value}</h2>
const StoreFactLine = ({ value }: StoreFaceLineType) => (
  <p className="hc-ma-none">{value}</p>
)
const StoreFactPhone = ({ value }: StoreFaceLineType) => (
  <Anchor href={`tel:${value}`}>{value}</Anchor>
)
const StoreFactHours = ({ day, value }: StoreFaceHourType) => (
  <Grid.Container>
    <Grid.Item xs={4} className="hc-pv-min hc-pr-none">
      {day}
    </Grid.Item>
    <Grid.Item xs={8} className="hc-pv-min hc-pr-none">
      {value}
    </Grid.Item>
  </Grid.Container>
)
const StoreFactExpandable = ({ value, children }: StoreFaceExpandedType) => {
  return (
    <ExpandableSection padding="none">
      <StoreFactHeading value={value} />
      <ExpandableSection.Content>{children}</ExpandableSection.Content>
    </ExpandableSection>
  )
}

const StoreFactCapabilityLine = ({
  value,
  className,
}: StoreFaceCapabilityLineType) => <p className={className}>{value}</p>
const StoreFactCapability = (capability: StoreFaceCapabilityType) => {
  const { contact } = TRANSLATION_KEYS
  const { t } = useEssentials()
  const capabilityContainerClass = 'hc-mv-sm'

  const { capability_name, contactDetails, capability_hours } = capability
  if (!capability_hours && !contactDetails) {
    return (
      <StoreFactCapabilityLine
        value={capability_name}
        className={capabilityContainerClass}
      />
    )
  }

  return (
    <ExpandableSection className={capabilityContainerClass} padding="none">
      <StoreFactCapabilityLine value={capability_name} className="hc-mv-none" />
      <ExpandableSection.Content>
        {contactDetails && (
          <>
            {contactDetails.contact_main && (
              <StoreFactLine
                value={
                  <>
                    <span>{t(contact.mainPhone)}: </span>
                    <StoreFactPhone value={contactDetails.contact_main} />
                  </>
                }
              />
            )}
            {contactDetails.contact_fax && (
              <StoreFactLine
                value={
                  <>
                    <span>{t(contact.fax)}: </span>
                    <StoreFactPhone value={contactDetails.contact_fax} />
                  </>
                }
              />
            )}
            <br />
          </>
        )}
        {capability_hours && (
          <>
            {capability_hours.days.map((d, index: number) => {
              const key = `${capability_name}_${d.day}_${index}`
              const dayLabel =
                index === 0 || d.day !== capability_hours.days[index - 1].day
                  ? `${t(`${d.day.toLocaleLowerCase()}`)}:`
                  : ''
              if (!d.begin_time || !d.end_time) {
                return (
                  <StoreFactHours
                    day={`${t(`${d.day}`)}`}
                    value={`${t('Closed')}`}
                    key={`${key}_${index}`}
                  />
                )
              }
              const openTime = formatStoreTime(d.begin_time)
              const closeTime = formatStoreTime(d.end_time)
              return (
                <StoreFactHours
                  day={dayLabel}
                  value={`${openTime} to ${closeTime}`}
                  key={`${key}_${index}`}
                />
              )
            })}
          </>
        )}
      </ExpandableSection.Content>
    </ExpandableSection>
  )
}
