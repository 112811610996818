import { useState } from 'react'
import { useShortage } from 'hooks/shortage/useShortage'
import { BasicPlaceholder } from 'components/placeholder'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { TitledFilteredTable } from 'components/titled-filtered-table/title-filtered-table'
import { getTableFormat } from 'utils/table'
import { MerchandiseGroups } from './components/merchandise'
import {
  TableData,
  addLinkToFirstColumn,
  insertRows,
  removeLinkFromFirstColumn,
} from 'utils/data'
import { useShortageTopTen } from 'hooks/shortage/useShortageTopTen'
import { getLocationFilters } from 'utils/api'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { translateItem } from 'locales/utils'
import { useEssentials } from 'hooks/utils/useEssentials'

export const ShortageStore = () => {
  const { t, currentLocation, env, logout, session } = useEssentials()

  const filters = getLocationFilters(currentLocation)

  const [parent, setParent] = useState<string>('')

  const { data, isLoading, isError, refetch, remove, error } = useShortage({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    filters,
  })
  useRefresh(refetch, remove)
  const count = useCountdown(5, logout, error as Error)

  const { data: subData } = useShortageTopTen({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath: parent,
  })

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }

  if (isError || !data) {
    return <ErrorAlert error={error as Error} count={count} />
  }

  const addChildRows = (data: TableData, subData: TableData) => {
    subData.rows = removeLinkFromFirstColumn(subData.rows)
    subData.rows = subData.rows.map((row) => {
      row[0].childOf = parent

      return row
    })
    const insertIndex =
      ttTable.rows.findIndex((row) => row[0].value === parent) + 1
    const newData = { ...ttTable }
    let newDataRows = [...ttTable.rows]
    insertRows(newDataRows, insertIndex, subData.rows)
    newData.rows = newDataRows
    data = newData

    return data
  }

  let derivedData = [...data]
  const [inventoryDates, , topTen] = derivedData

  const { shortage } = TRANSLATION_KEYS
  const { top10: topTenKeys } = shortage
  let { table: idTable } = inventoryDates
  let { title: ttTitle, table: ttTable } = topTen

  ttTitle = translateItem({ t, item: ttTitle, translation: topTenKeys })

  const handleClick = (parentValue: string) => {
    if (ttTable) {
      const match = ttTable?.rows.filter(
        (row) => row[0].childOf === parentValue,
      )
      if (parent === parentValue || match.length > 0) {
        const filtered = ttTable?.rows.filter(
          (row) => row[0].childOf !== parentValue,
        )
        ttTable.rows = filtered
        setParent('')
      } else {
        setParent(parentValue)
      }
    }
  }

  ttTable.rows = addLinkToFirstColumn(ttTable.rows)
  if (subData) {
    ttTable = addChildRows(ttTable, subData)
  }

  const widths = [150, ...getTableFormat(ttTable.headings?.length ?? 20, 90)]

  return (
    <div>
      <Grid.Container className="hc-mb-normal" justify="space-between">
        <Grid.Item xs={5} className="hc-mb-none">
          <span className="hc-fs-sm hc-ma-none">
            {translateItem({
              t,
              item: idTable.rows[0][0].value,
              translation: shortage,
            })}
          </span>
        </Grid.Item>
        <Grid.Item xs={7} className="hc-mb-none hc-ta-right" justify="flex-end">
          <span>{idTable.rows[0][1].value}</span>
        </Grid.Item>
        <Grid.Item xs={5} className="hc-pt-none">
          <span className="hc-fs-sm hc-ma-none">
            {translateItem({
              t,
              item: idTable.rows[1][0].value,
              translation: shortage,
            })}
          </span>
        </Grid.Item>
        <Grid.Item xs={7} className="hc-pt-none hc-ta-right" justify="flex-end">
          <span>{idTable.rows[1][1].value}</span>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container className="hc-pv-md">
        <MerchandiseGroups />
      </Grid.Container>
      <Grid.Container className="hc-pv-md">
        <TitledFilteredTable
          title={t(topTenKeys.title)}
          data={ttTable}
          widths={widths}
          handleClick={handleClick}
          pinFirstColumn={true}
          key={ttTitle}
          isLoading={isLoading}
        />
      </Grid.Container>
    </div>
  )
}
