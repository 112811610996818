import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { SalesAreaOfStoreFetchProps } from 'types/api'
import { TIME } from 'utils/misc'
import type { IResponse } from 'utils/api'
import { fetchSalesAreaOfStore } from 'queries/sales/area-of-store'
import { format } from 'date-fns'
import { isStoreOrRGD } from 'utils/location'

export function useSalesAreaOfStore({
  env,
  accessToken,
  location,
  dynamicSubPath,
  timePeriod,
}: SalesAreaOfStoreFetchProps): IResponse {
  const { tenMinutes } = TIME
  if (!isStoreOrRGD(location)) {
    location = ''
  }

  const dynamicSubPathArr = dynamicSubPath?.split('|') ?? []
  let dynamicUrl = ''
  if (dynamicSubPathArr) {
    const [group = '', center = '', division = '', department = ''] =
      dynamicSubPathArr
    dynamicUrl = [
      group && `/groups/${group}`,
      center && `centers/${center}`,
      division && `divisions/${division}`,
      department && `departments/${department}`,
    ]
      .filter(Boolean)
      .join('/')
  }

  let filters: any = {}
  if (timePeriod === 'today') {
    filters = {
      intraday: `${true}`,
      sales_date: format(new Date(), 'yyyy-MM-dd'),
      time_dimension: timePeriod,
    }
  } else {
    filters = {
      intraday: `${false}`,
      sales_date: '',
      time_dimension: timePeriod,
    }
  }

  const { data, isLoading, isError, refetch, remove, error } = useQuery(
    [QueryKeys.SALES_AREA_OF_STORE, location, timePeriod, dynamicUrl],
    () =>
      fetchSalesAreaOfStore({
        env,
        accessToken,
        dynamicSubPath: dynamicUrl,
        location,
        filters,
      }),
    {
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
      retry: false,
    },
  )

  return { data, isLoading, isError, refetch, remove, error }
}
