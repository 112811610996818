import { isDistrict, isGroup, isRegion, isRGD, isStoreOrRGD } from './location'

export const getGreenfieldDrillValues = (location: string) => {
  if (!isStoreOrRGD(location)) {
    return undefined
  }

  let params = {} as { [key: string]: string }
  if (isRGD(location)) {
    if (isRegion(location)) {
      params.drill_dimension = 'REGION'
    } else if (isGroup(location)) {
      params.drill_dimension = 'GROUP'
    } else if (isDistrict(location)) {
      params.drill_dimension = 'DISTRICT'
    }
    params.drill_value = location
  }

  return params
}

export const getGreenfieldFilterValues = (
  filterDimension: string,
  filterValue: any,
) => {
  let params = {} as { [key: string]: string }

  params.filter_dimension = filterDimension
  params.filter_value = filterValue

  return params
}

export const getGreenfieldLocationFilter = (location: string) => {
  let params = {} as { [key: string]: string }

  params.location_id = location

  return params
}
