import { useState, useEffect, useCallback } from 'react'
import { Container } from 'components/container/Container'
import {
  GeneralizedComponent,
  GeneralizedComponentPayloadType,
} from 'components/generalized-component/generalized-component'
import { useSalesDetails } from 'hooks/sales/useSalesDetails'
import { getLocationFilters } from 'utils/api'
import { BasicPlaceholder } from 'components/placeholder'
import type { Cell, TableData } from 'utils/data'
import { ErrorAlert } from 'components/error-fallback'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const SalesByMerchandise = () => {
  const { t, currentLocation, env, session } = useEssentials()
  const { filters: filtersKeys, sales } = TRANSLATION_KEYS
  const { details } = sales
  const { merch } = details
  const [drills, setDrills] = useState<string[]>([])
  const filters = getLocationFilters(currentLocation)
  const [dataState, setDataState] = useState<TableData>()
  const [payload, setPayload] = useState<GeneralizedComponentPayloadType>()
  const [timePeriod, setTimePeriod] = useState<string>('today')

  const handleDrillUpdate = useCallback((newDrills: string[]) => {
    setDrills(newDrills)
  }, [])

  const handleIndexUpdate = useCallback((index: number) => {
    const timePeriods = [
      'today',
      'yesterday',
      'week_to_day',
      'last_week',
      'month_to_day',
      'year_to_day',
    ]
    setTimePeriod(timePeriods[index])
  }, [])

  const {
    data,
    isError: todaysError,
    isLoading,
  } = useSalesDetails({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath: drills.join('|'),
    filters,
    timePeriod: timePeriod,
  })

  useEffect(() => {
    if (data) {
      setDataState(data)
    }
  }, [data])

  const generateSalesData = useCallback(
    (data: TableData | undefined) => ({
      headings: data?.headings
        ? data.headings.slice(0, 4).map((heading: { value: string }) => ({
            value: heading.value,
          }))
        : [],
      rows: data?.rows?.map((row: Cell[]) => row.slice(0, 4)),
    }),
    [],
  )

  const generateSalesMixData = useCallback(
    (data: TableData | undefined) => ({
      headings: data?.headings
        ? [
            { value: data.headings[0]?.value },
            { value: data.headings[1]?.value },
            { value: data.headings[9]?.value },
          ]
        : [],
      rows: data?.rows?.map((row: Cell[]) => [
        row.slice(0, 1)[0],
        ...row.slice(1, 2),
        ...row.slice(9, 10),
      ]),
    }),
    [],
  )

  const generateSalesMixDataCompPercentage = useCallback(
    (data: TableData | undefined) => ({
      headings: data?.headings
        ? [
            { value: data.headings[0]?.value },
            { value: data.headings[9]?.value },
          ]
        : [],
      rows: data?.rows?.map((row: Cell[]) => [
        row.slice(0, 1)[0],
        ...row.slice(9, 10),
      ]),
    }),
    [],
  )

  const generateCompData = useCallback(
    (data: TableData | undefined) => ({
      headings: data?.headings
        ? [
            { value: data.headings[0]?.value },
            { value: data.headings[4]?.value },
            { value: data.headings[5]?.value },
            { value: data.headings[6]?.value },
          ]
        : [],
      rows: data?.rows?.map((row: Cell[]) => [
        row.slice(0, 1)[0],
        ...row.slice(4, 7),
      ]),
    }),
    [],
  )

  useEffect(() => {
    const timePeriods = [
      'today',
      'yesterday',
      'week to date',
      'last week',
      'month to date',
      'year to date',
    ]

    const viewInDollars = timePeriods.map((period) => {
      const data = {
        [t(merch.tabs.sales)]: generateSalesData(dataState),
      }

      if (period !== 'today') {
        data[t(merch.tabs.salesMix)] = generateSalesMixData(dataState)
      }

      return {
        title: t(period),
        subtitle: '',
        data,
      }
    })

    const compPercent = timePeriods.map((period) => {
      const data = {
        [t(merch.tabs.sales)]: generateCompData(dataState),
      }

      if (period !== 'today') {
        data[t(merch.tabs.salesMix)] =
          generateSalesMixDataCompPercentage(dataState)
      }

      return {
        title: t(period),
        subtitle: '',
        data,
      }
    })

    const generalizedComponentData: GeneralizedComponentPayloadType = {
      [t(filtersKeys.viewInDollars)]: viewInDollars,
      [t(filtersKeys.compPercent)]: compPercent,
    }

    setPayload(generalizedComponentData)
  }, [
    dataState,
    t,
    timePeriod,
    generateSalesData,
    generateSalesMixData,
    generateCompData,
    generateSalesMixDataCompPercentage,
    merch.tabs,
    filtersKeys.viewInDollars,
    filtersKeys.compPercent,
  ])

  if (todaysError) {
    return <ErrorAlert />
  }

  if (!payload || dataState === undefined) {
    return (
      <div className="placeholder-container" data-testid="placeholder-grid">
        <BasicPlaceholder type="table" rows={5} />
      </div>
    )
  }

  return (
    <Container className="sales-by-merchandise" role="main">
      <GeneralizedComponent
        payload={payload}
        title={t(sales.details.merch.title)}
        onDrillUpdate={handleDrillUpdate}
        onIndexUpdate={handleIndexUpdate}
        isLoading={isLoading}
        loadingComponent={
          <div className="placeholder-container" data-testid="placeholder-grid">
            <BasicPlaceholder type="table" rows={5} />
          </div>
        }
      ></GeneralizedComponent>
    </Container>
  )
}
