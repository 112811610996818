import { Grid, Table } from '@enterprise-ui/canvas-ui-react'
import { BasicPlaceholder } from 'components/placeholder'
import { TitledTableData, buildDataTableObject } from 'utils/data'
import { getLocationFilters } from 'utils/api'
import { useAssetsProtectionStore } from 'hooks/assets-protection/useAssetsProtectionStore'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback'
import { translateItem } from 'locales/utils'
import { useEssentials } from 'hooks/utils/useEssentials'

export const PayrollStore = () => {
  const { t, currentLocation, logout, env, session } = useEssentials()
  const { assetsProtection } = TRANSLATION_KEYS

  const filters = getLocationFilters(currentLocation)
  let { data, isLoading, isError, refetch, remove, error } =
    useAssetsProtectionStore({
      env,
      accessToken: session?.accessToken ?? '',
      location: currentLocation,
      filters,
    })
  useRefresh(refetch, remove)
  const count = useCountdown(5, logout, error as Error)

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }
  if (isError || !data) {
    return <ErrorAlert error={error as Error} count={count} />
  }

  const derivedData = structuredClone(data)
  const [weekly, monthly, yearly] = derivedData

  return (
    <div>
      {[weekly, monthly, yearly].map((titledTable: TitledTableData) => {
        const { title, table } = titledTable
        const translatedTitle = translateItem({
          t,
          item: title,
          translation: assetsProtection,
        })

        return (
          <Grid.Container className="hc-pv-md">
            <Grid.Item xs={12} className="hc-pb-none">
              <p className="hc-pa-min hc-lh-expanded hc-txt-lowercase">
                {translatedTitle}
              </p>
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pt-none">
              <Table
                data={buildDataTableObject({
                  currentData: table,
                  widths: [],
                })}
                name={title}
              />
            </Grid.Item>
          </Grid.Container>
        )
      })}
    </div>
  )
}
