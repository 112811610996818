import qs from 'qs'
import { MPMEnvType } from 'utils/env'
import { useAnalytics } from '@praxis/component-analytics'
import { FireflyEvent } from '../utils/fireflyEvent'
import { logEvent, LogLevel } from '@praxis/component-logging'

export type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE'

type FetchProps = {
  env: MPMEnvType
  accessToken: string
  location?: string
  dynamicSubPath?: string
  current_date?: string
  filters?:
    | {
        [key: string]: string
      }
    | [
        {
          [key: string]: string
        },
      ]
  body?: any
  method?: MethodType
  trackEvent?: ReturnType<typeof useAnalytics>['trackEvent']
}

export const fetchGeneralData =
  <T>(rootPath: string) =>
  async ({
    env,
    accessToken,
    location,
    dynamicSubPath,
    current_date,
    filters,
    method = 'GET',
    body = null,
    trackEvent,
  }: FetchProps): Promise<T> => {
    let startTime, endTime
    const { apiKey, baseUrl } = env
    let url = `${baseUrl}${rootPath}`
    if (dynamicSubPath) {
      url += dynamicSubPath
    }

    const parameters = {
      key: apiKey,
      ...filters,
    } as { [key: string]: string }
    if (current_date) {
      parameters.current_date = current_date
    } else if (location) {
      parameters.location = location
    }
    const queryParams = qs.stringify(parameters)
    url += `?${queryParams}`

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': apiKey,
      },
      body: body ? JSON.stringify(body) : null,
    }
    let response
    startTime = new Date().getTime()
    try {
      response = await fetch(url, options)
    } catch (error) {
      logEvent(
        {
          message: `Error calling dynamicSubPath endpoint - ${error}`,
        },
        { level: LogLevel.Error },
      )
      endTime = new Date().getTime()
      if (trackEvent) {
        trackEvent!(
          createFireflyEvent(
            url,
            (endTime - startTime) / 1000,
            response,
            error instanceof Error ? error.message : 'Request failed',
          ),
        )
      }
      throw error
    }

    if (!response.ok) {
      const responseErrorDescription = await response.text()
      endTime = new Date().getTime()
      if (trackEvent) {
        trackEvent!(
          createFireflyEvent(
            url,
            (endTime - startTime) / 1000,
            response,
            responseErrorDescription,
          ),
        )
      }
      logEvent(
        {
          message: `Error calling dynamicSubPath endpoint - ${response.status.toString()} | Error Description - ${responseErrorDescription}`,
        },
        { level: LogLevel.Error },
      )
      throw new Error(
        `Error Status Code - ${response.status.toString()} | Error Description - ${responseErrorDescription}`,
      )
    }

    const parsedResponse = await response.json()
    endTime = new Date().getTime()
    if (trackEvent) {
      trackEvent!(
        createFireflyEvent(url, (endTime - startTime) / 1000, response),
      )
    }

    return parsedResponse
  }

export function createFireflyEvent(
  url: string,
  responseTime: number,
  response: any,
  errorMessage?: string,
): FireflyEvent {
  let errorObj = undefined
  if (errorMessage) {
    errorObj = {
      id: errorMessage ? response.status : '',
      message: errorMessage ? errorMessage : '',
    }
  }
  return {
    application: {
      version: process.env.REACT_APP_VERSION || '0.0.0',
      name: 'MPMPWA',
    },
    customMetrics: {
      metric4: responseTime,
      metric5: response.status,
    },
    event: {
      type: 'Service Call',
    },
    error: errorObj,
    view: {
      additionalInfo: {
        request_url: url,
        x_api_id: response.headers.get('x-api-id'),
        response_time: `${responseTime} secs`,
      },
    },
  }
}
