import { useState } from 'react'
import EnterpriseIcon, {
  CartIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CreditCardIcon,
} from '@enterprise-ui/icons'
import {
  Button,
  ButtonGroup,
  Grid,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import { Container } from 'components/container/Container'
import { useSalesOverview } from 'hooks/sales/useSalesOverview'
import { Link } from 'react-router-dom'
import {
  formatCurrency,
  formatPercent,
  formatTransactions,
  getDynamicSubPath,
  getLocationFilter,
  getPercentClass,
  getPercentComplete,
  getRealPercent,
} from '../helpers'
import { BasicPlaceholder } from 'components/placeholder'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback'
import { useEssentials } from 'hooks/utils/useEssentials'
import { format } from 'date-fns'
import { useSwipeToIndex } from 'components/generalized-component/useSwipeToIndex'
import { TRANSLATION_KEYS } from 'locales/translation-keys'

export const SalesOverview = () => {
  const { timePeriods: timePeriodsKeys, sales } = TRANSLATION_KEYS
  const { overview } = sales
  const { currentLocation, env, t, logout, session } = useEssentials()

  const locationFilter = getLocationFilter(currentLocation)
  const currentDate = new Date()
  const filters = {
    date: format(currentDate, 'yyyy-MM-dd'),
    source: 'greenfield',
    ...locationFilter,
  }

  const [timePeriodIndex, setTimePeriodIndex] = useState<number>(0)

  const result = useSalesOverview({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath: getDynamicSubPath(currentLocation),
    filters,
  })

  const [today, yesterday, weekToDate, lastWeek, monthToDate, yearToDate] =
    result

  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useSwipeToIndex(
    {
      timePeriods: Array(result.length).fill(''),
      setTimePeriodIndex,
    },
  )

  const { refetch, remove, error } = today
  useRefresh(refetch, remove)
  const count = useCountdown(5, logout, error as Error)

  if (result[timePeriodIndex].isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={10} columns={3} />
      </div>
    )
  }

  const isError = result.every((r) => r.isError)
  if (isError) {
    return <ErrorAlert error={error as Error} count={count} />
  }

  const payload = [
    {
      title: t(timePeriodsKeys.today),
      subtitle: '',
      data: today.data,
    },
    {
      title: t(timePeriodsKeys.yesterday),
      subtitle: '',
      data: yesterday.data,
    },
    {
      title: t(timePeriodsKeys.weekToDate),
      subtitle: '',
      data: weekToDate.data,
    },
    {
      title: t(timePeriodsKeys.lastWeek),
      subtitle: '',
      data: lastWeek.data,
    },
    {
      title: t(timePeriodsKeys.monthToDate),
      subtitle: '',
      data: monthToDate.data,
    },
    {
      title: t(timePeriodsKeys.yearToDate),
      subtitle: '',
      data: yearToDate.data,
    },
  ].filter((item) => item.data)
  const currentDataSet = payload[timePeriodIndex].data[0]
  const uniqueKey: string =
    Object.keys(currentDataSet).find(
      (key) =>
        ![
          'id',
          'location_id',
          'date',
          'sales_by_location',
          'region_id',
          'group_id',
          'district_id',
          'store_id',
        ].includes(key),
    ) || ''
  const currentData = currentDataSet[uniqueKey]

  const timePeriods = payload.map((item) => item.title)
  const currentTimePeriodKey = timePeriods[timePeriodIndex]
  const currentTimePeriod = timePeriods[timePeriodIndex]
  const realGoalPercent = getRealPercent(
    currentData.forecast_pct,
    currentData.forecast_status,
  )
  const realGoalPercentFormatted = formatPercent(realGoalPercent)
  const salesFormatted = formatCurrency(currentData.sales)
  const todayLink = (
    <Button
      type="ghost"
      className="hc-ma-none hc-pa-none"
      aria-label={t(overview.hourlySales)}
    >
      <Link to={`hourly`} className="hc-fs-lg">
        {salesFormatted}
      </Link>
    </Button>
  )
  const todaySalesElement =
    currentTimePeriod === 'today' ? todayLink : salesFormatted

  const isNonIntraDay = currentTimePeriodKey !== timePeriodsKeys.today

  return (
    <Container
      role="main"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Grid.Container align="center" justify="space-between">
        <Grid.Item xs={4} className="hc-fs-md hc-ph-none">
          {payload[timePeriodIndex].title}
        </Grid.Item>
        <Grid.Item xs={4}>
          <ButtonGroup className={'time-periods'}>
            {payload.map((item: any, index: number) => {
              return (
                <Button
                  type={index === timePeriodIndex ? 'primary' : 'secondary'}
                  onClick={() => setTimePeriodIndex(index)}
                  key={payload[index].title}
                >
                  {item.title}
                </Button>
              )
            })}
          </ButtonGroup>
        </Grid.Item>
        <Grid.Item xs={4}></Grid.Item>
      </Grid.Container>
      <Grid.Container
        align="center"
        justify="space-around"
        className="hc-pb-lg"
      >
        <Grid.Item xs={9} className="hc-ph-none">
          <span id="sales-info" className="hc-sr-only">
            {salesFormatted} of {formatCurrency(currentData.forecast)} with a
            goal percentage of {realGoalPercentFormatted}
          </span>
          <span className="hc-fs-lg">{todaySalesElement}</span>
          <span aria-hidden="true">
            {' '}
            of {formatCurrency(currentData.forecast)}{' '}
            <span className={getPercentClass(realGoalPercent)}>
              {realGoalPercentFormatted}
            </span>
          </span>
          <ProgressBar
            percentComplete={getPercentComplete(realGoalPercent)}
            className={realGoalPercent >= 80 ? 'success' : 'error'}
          />
        </Grid.Item>
      </Grid.Container>
      <SalesOverviewDataRow
        label={t(t(overview.originated))}
        dollars={currentData.sales_breakdown.originated}
        percent={currentData.sales_breakdown.originated_pct}
        isChild={false}
      />
      {isNonIntraDay && (
        <SalesOverviewDataRow
          label={t(t(overview.lineBusting))}
          dollars={currentData.sales_breakdown.line_bust}
          percent={currentData.sales_breakdown.line_bust_pct}
        />
      )}
      <SalesOverviewDataRow
        label={t(t(overview.fulfilled))}
        dollars={currentData.fulfilled_sales}
        percent={currentData.fulfilled_pct}
        isChild={false}
      />
      <SalesOverviewDataRow
        label={t(overview.shipFromStore)}
        dollars={currentData.sales_breakdown.ship_from_store}
        percent={currentData.sales_breakdown.ship_from_store_pct}
      />
      {isNonIntraDay && (
        <SalesOverviewDataRow
          label={t(overview.driveUp)}
          dollars={currentData.sales_breakdown.drive_up}
          percent={currentData.sales_breakdown.drive_up_pct}
        />
      )}
      {isNonIntraDay && (
        <SalesOverviewDataRow
          label={t(overview.orderPickup)}
          dollars={currentData.sales_breakdown.order_pickup}
          percent={currentData.sales_breakdown.order_pickup_pct}
        />
      )}
      {isNonIntraDay && (
        <SalesOverviewDataRow
          label={t(overview.shipt)}
          dollars={currentData.sales_breakdown.shipt}
          percent={currentData.sales_breakdown.shipt_pct}
        />
      )}
      {isNonIntraDay && (
        <SalesOverviewDataRow
          label={t(overview.otherSales)}
          isChild={false}
          showData={false}
        />
      )}
      {isNonIntraDay && (
        <SalesOverviewDataRow
          label={t(overview.saveTheSale)}
          dollars={currentData.sales_breakdown.mycheckout}
          percent={currentData.sales_breakdown.mycheckout_pct}
        />
      )}
      <SalesOverviewDataRow
        label={t(overview.goal)}
        dollars={currentData.goal}
        percent={currentData.goal_pct}
        isChild={false}
      />
      <SalesOverviewDataRow
        label={t(overview.lastYear)}
        isChild={false}
        showData={false}
      />
      <SalesOverviewDataRow
        label="comp %"
        dollars={
          currentTimePeriod === 'today'
            ? currentData.last_year_total_sales
            : currentData.last_year_sales
        }
        percent={currentData.ly_admapped}
      />
      <SalesOverviewDataRow
        label={t(overview.originatedCompPercent)}
        dollars={currentData.last_year_total_sales_originated}
        percent={currentData.originated_ly_admapped}
      />
      <SalesOverviewDataRow
        label={t(overview.fulfilledCompPercent)}
        dollars={currentData.last_year_total_sales_fulfilled}
        percent={currentData.fulfilled_ly_admapped}
      />
      <Grid.Container>
        <Grid.Item xs={12} className="hc-ph-none">
          <hr />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs={6} className="hc-pl-none">
          <Grid.Container>
            <Grid.Item>
              <strong className="heading-with-icon">
                <EnterpriseIcon icon={CartIcon} />
                {t(overview.basketSize)}
              </strong>
            </Grid.Item>
          </Grid.Container>
          <SalesOverviewSmallwDataRow
            label={t(overview.total)}
            value={currentData.average_basket_size}
            type="currency"
          />
          <SalesOverviewSmallwDataRow
            label="ly"
            value={currentData.last_year_average_basket_size}
            type="currency"
          />
          {isNonIntraDay && (
            <SalesOverviewSmallwDataRow
              label={t(overview.bpToLy)}
              value={currentData.basket_size_comp}
              arrow={currentData.basket_icon}
              type="currency"
            />
          )}
          {isNonIntraDay && (
            <SalesOverviewSmallwDataRow
              label={t(overview.bpToLyPercent)}
              value={currentData.basket_size_comp_pct}
              arrow={currentData.basket_icon}
              type="percent"
            />
          )}
        </Grid.Item>
        <Grid.Item xs={6} className="hc-pl-none">
          <Grid.Container>
            <Grid.Item>
              <strong className="heading-with-icon">
                <EnterpriseIcon icon={CreditCardIcon} />
                {t(overview.transactions)}
              </strong>
            </Grid.Item>
          </Grid.Container>
          <SalesOverviewSmallwDataRow
            label={t(overview.total)}
            value={currentData.total_transaction_count}
            edgePadding={true}
            type="number"
          />
          <SalesOverviewSmallwDataRow
            label={t(overview.ly)}
            value={currentData.last_year_total_transaction_count}
            edgePadding={true}
            type="number"
          />
          {isNonIntraDay && (
            <SalesOverviewSmallwDataRow
              label={t(overview.bpToLy)}
              value={currentData.transaction_comp}
              arrow={currentData.transaction_icon}
              edgePadding={true}
              type="number"
            />
          )}
          {isNonIntraDay && (
            <SalesOverviewSmallwDataRow
              label={t(overview.bpToLyPercent)}
              value={currentData.transaction_comp_pct}
              arrow={currentData.transaction_icon}
              edgePadding={true}
              type="percent"
            />
          )}
        </Grid.Item>
      </Grid.Container>
    </Container>
  )
}

type SalesOverviewDataRowProps = {
  label: string
  showData?: boolean
  isChild?: boolean
  dollars?: number
  percent?: number
}
const SalesOverviewDataRow = ({
  label,
  showData = true,
  isChild = true,
  dollars,
  percent,
}: SalesOverviewDataRowProps) => {
  if (!showData) {
    return (
      <Grid.Container>
        <Grid.Item xs={6} className={isChild ? 'hc-pl-expanded' : 'hc-ph-min'}>
          {isChild ? label : <strong>{label}</strong>}
        </Grid.Item>
      </Grid.Container>
    )
  }
  return (
    <Grid.Container>
      <Grid.Item className="hc-sr-only">
        {isChild ? label : `Label: ${label}`}
        {dollars ? `, Amount: ${formatCurrency(dollars)}` : ''}
        {percent
          ? `, Percentage: ${formatPercent(percent) || '0.00%'}`
          : ', Percentage: 0.00%'}
      </Grid.Item>
      <Grid.Item
        xs={6}
        className={isChild ? 'hc-pl-expanded' : 'hc-ph-min'}
        aria-hidden="true"
      >
        {isChild ? label : <strong>{label}</strong>}
      </Grid.Item>
      {dollars ? (
        <Grid.Item xs={3} className="hc-ph-min hc-ta-right" aria-hidden="true">
          {formatCurrency(dollars)}
        </Grid.Item>
      ) : (
        <Grid.Item xs={3} className="hc-ph-min hc-ta-right" aria-hidden="true">
          {formatCurrency(0)}
        </Grid.Item>
      )}
      {percent ? (
        <Grid.Item
          xs={3}
          className="hc-ph-min hc-ta-right"
          role="cell"
          aria-hidden="true"
        >
          <Grid.Item className={getPercentClass(percent)} role="cell">
            {formatPercent(percent) || '0.00%'}
          </Grid.Item>
        </Grid.Item>
      ) : (
        <Grid.Item
          xs={3}
          className="hc-ph-min hc-ta-right"
          role="cell"
          aria-hidden="true"
        >
          <Grid.Item role="cell">{'0.00'}%</Grid.Item>
        </Grid.Item>
      )}
    </Grid.Container>
  )
}

type SalesOverviewSmallDataRowProps = {
  label: string
  value: number
  arrow?: string
  edgePadding?: boolean
  type?: 'number' | 'currency' | 'percent'
}
const SalesOverviewSmallwDataRow = ({
  label,
  value,
  arrow,
  edgePadding = false,
  type = 'currency',
}: SalesOverviewSmallDataRowProps) => {
  const icon = arrow === 'up' ? ChevronUpIcon : ChevronDownIcon
  const iconClass = arrow === 'up' ? 'hc-clr-success' : 'hc-clr-error'
  const formattedValue =
    type === 'percent'
      ? formatPercent(value)
      : type === 'currency'
        ? formatCurrency(value)
        : formatTransactions(value)

  return (
    <Grid.Container className="hc-pa-none">
      <Grid.Item xs={6} className="hc-pr-min">
        {label}
      </Grid.Item>
      <Grid.Item
        xs={6}
        className={`hc-ta-right ${edgePadding ? 'hc-ph-min' : 'hc-pl-min'}`}
      >
        {formattedValue}

        {arrow && (
          <>
            <EnterpriseIcon
              icon={icon}
              size="sm"
              className={`${iconClass} hc-ml-min`}
            />
          </>
        )}
      </Grid.Item>
    </Grid.Container>
  )
}
