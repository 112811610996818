import { useState } from 'react'
import { Button, Grid, Table } from '@enterprise-ui/canvas-ui-react'
import { TableData, buildDataTableObject } from 'utils/data'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { translateItem } from 'locales/utils'
import { useEssentials } from 'hooks/utils/useEssentials'

type TitledFilteredTableProps = {
  title: string
  data: TableData
  widths: number[]
  selectedCell?: string
  handleClick?: (value: string) => void
  pinFirstColumn?: boolean
  isLoading?: boolean
}

export const TitledFilteredTable = ({
  title,
  data,
  widths,
  handleClick,
  pinFirstColumn,
  selectedCell,
  isLoading,
}: TitledFilteredTableProps) => {
  const { t } = useEssentials()
  const { filters } = TRANSLATION_KEYS
  let derivedData = { ...data }
  const options = ['$', '%']
  const [currentIndex, setCurrentIndex] = useState(0)
  const selectedOption = options[currentIndex]
  const handleToggle = () => setCurrentIndex((prevIndex) => 1 - prevIndex)

  derivedData.headings = derivedData.headings
    ?.filter((r, i) => i === 0 || r.value.includes(selectedOption))
    .map((heading) => {
      return { ...heading, value: heading.value }
    })
  derivedData.rows = derivedData.rows?.map((row) => {
    return row.filter(
      (cell, index) => index === 0 || cell.value.includes(selectedOption),
    )
  })
  derivedData.footer = derivedData.footer
    ?.filter((r, i) => i === 0 || r.value.includes(selectedOption))
    .map((footer) => {
      return { ...footer, value: footer.value }
    })

  const getViewLabelStr = (index: number) => {
    if (index === 0) {
      return filters.viewInPercent
    }

    return filters.viewInDollars
  }
  const viewLabel = translateItem({
    t,
    item: getViewLabelStr(currentIndex),
    translation: TRANSLATION_KEYS,
  })

  return (
    <>
      <Grid.Item xs={8} className="hc-pb-none">
        <p className="hc-pa-min hc-lh-expanded">{title}</p>
      </Grid.Item>
      <Grid.Item xs={4} className="hc-pb-none hc-ta-right">
        <Button type="ghost" onClick={handleToggle}>
          {t(viewLabel)}
        </Button>
      </Grid.Item>
      <Grid.Item xs={12} className="hc-pt-none">
        <Table
          data={buildDataTableObject({
            currentData: derivedData,
            widths,
            handleClick,
            pinFirstColumn,
            selectedCell,
            isLoading,
          })}
          name={title}
          scrollableWidth={true}
        />
      </Grid.Item>
    </>
  )
}
