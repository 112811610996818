import { SetURLSearchParams } from 'react-router-dom'
import { UrlParams } from 'enums/url-params'

export const isProd = (environment: string) => environment.includes('prod')
export const isNonProd = (environment: string) => !environment.includes('prod')

type UrlParamsType = {
  params: URLSearchParams
  setParams: SetURLSearchParams
}
export const clearLocationParam = ({ params, setParams }: UrlParamsType) => {
  const locationParamExists = params.get(UrlParams.LOCATION_ID)
  if (locationParamExists) {
    params.delete(UrlParams.LOCATION_ID)
    setParams(params)
  }
}

export const isEntitled = (
  greenfieldGroups: string[],
  entitledGroups: string[],
) => entitledGroups.some((group) => greenfieldGroups?.includes(group))
