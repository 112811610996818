import { BasicPlaceholder } from 'components/placeholder'
import {
  DataTableObjectType,
  TableData,
  buildDataTableObject,
} from 'utils/data'
import { getTableFormat } from 'utils/table'
import { getLocationTableIndexes, pickData } from '../helpers'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { ErrorAlert } from 'components/error-fallback'
import { useEssentials } from 'hooks/utils/useEssentials'

interface INonStoreLocation {
  data: TableData | undefined
  isLoading: boolean
  isError: boolean
  currentLocation: string
  bottomLeftCell: string
  error: Error
  count: number
}
export const NonStoreLocation = ({
  data,
  isLoading,
  isError,
  currentLocation,
  bottomLeftCell,
  error,
  count,
}: INonStoreLocation) => {
  const { t } = useEssentials()
  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }
  if (isError || !data) {
    return <ErrorAlert error={error as Error} count={count} />
  }

  data.headings = data?.headings?.map((heading) => ({
    ...heading,
    value: t(heading.value),
  }))

  const widths = [100, ...getTableFormat(data?.rows?.length ?? 20, 85)]
  const { startIndex: tableStartIndex, endIndex: tableEndIndex } =
    getLocationTableIndexes(currentLocation)
  const objParams = {
    currentData: pickData(
      data,
      currentLocation,
      tableStartIndex,
      tableEndIndex,
      t('loc'),
    ),
    widths,
    bottomLeftCell,
    pinFirstColumn: true,
  } as DataTableObjectType
  const obj = buildDataTableObject(objParams)
  return <Table data={obj} scrollableWidth={true} />
}
