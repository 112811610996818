import * as ERROR_CONSTANTS from './error'
export namespace Constants {
  export const AUTHENTICATE = 'authenticate'
  export const LOGIN_FAIL = 'failed to log in'
  export const LOGIN_SUCCESSFUL = 'successfully logged in'
  export const ERROR = ERROR_CONSTANTS
  // use with REACT_APP_ENV to put the app in a mocked auth state
  export const FAKE_AUTH = 'fakeauth'
  export const APP_ID = 'mpm'
  export const GREENFIELD_CARDS = {
    SALES: {
      LOCATION_INTRADAY: '2401476',
      LOCATION_HISTORICAL: '2426812',
      HOURLY: '2463865',
    },
  }
  export const GREENFIELD_GROUPS = {
    ASSETS_PROTECTION: 'MPM-ASSETS-PROTECTION',
    PAYROLL: 'MPM-PAYROLL',
    SALES_LOCATION: 'MPM-SALES-LOCATION',
    ADMIN_PROD: 'APP-OAUTH2-MPM-ADMIN-PROD',
  }
}

export const MethodConstants = {
  GET: 'GET' as const,
  POST: 'POST' as const,
  PUT: 'PUT' as const,
  PATCH: 'PATCH' as const,
  DELETE: 'DELETE' as const,
}
