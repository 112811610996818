import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { en } from './locales/en'
import { es } from './locales/es'
import { ITranslationKeys } from './locales/ITranslationKeys'

export const resources: Record<string, { translation: ITranslationKeys }> = {
  en: { translation: en },
  es: { translation: es },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'production',
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: true,
    },
  })

export default i18n
