import { TitledTableData } from 'utils/data'
import { ApiSubUrls, Apis } from 'enums/api-constants'
import { fetchGeneralData } from 'queries/fetchData'

export const fetchAssetsProtectionPayrollData = fetchGeneralData<
  TitledTableData[]
>(`${ApiSubUrls[Apis.AGGREGATIONS]}/assets_protection/payroll`)

export const fetchAssetsProtectionTheftData = fetchGeneralData<
  TitledTableData[]
>(`${ApiSubUrls[Apis.AGGREGATIONS]}/assets_protection/theft`)
