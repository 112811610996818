export const TRANSLATION_KEYS = {
  alerts: 'alerts',
  alert: {
    heading: 'alert.heading',
    generic: 'alert.generic',
    expiredSession: 'alert.expiredSession',
    retry: 'alert.retry',
  },
  assetsProtection: {
    title: 'assetsProtection.title',
    tabs: {
      payroll: 'assetsProtection.tabs.payroll',
      theft: 'assetsProtection.tabs.theft',
      secure: 'assetsProtection.tabs.secure',
    },
  },
  locationSearch: {
    header: 'locationSearch.header',
    searchLabel: 'locationSearch.searchLabel',
    matchNotFound: 'locationSearch.matchNotFound',
    nearbyLocationsHeader: 'locationSearch.nearbyLocationsHeader',
  },
  payroll: {
    title: 'payroll.title',
    tabs: {
      location: 'payroll.tabs.location',
      workgroup: 'payroll.tabs.workgroup',
      overtime: 'payroll.tabs.overtime',
      monthly: 'payroll.tabs.monthly',
    },
    location: {
      title: 'payroll.location.title',
      payrollByLocation: 'payroll.location.payrollByLocation',
      payrollByWeek: 'payroll.location.payrollByWeek',
      projFlexHours: 'payroll.location.projFlexHours',
      guranteedFlexHours: 'payroll.location.guranteedFlexHours',
      bpFlextoplan: 'payroll.location.bpFlextoplan',
    },
    workgroup: {
      title: 'payroll.workgroup.title',
    },
    overtime: {
      title: 'payroll.overtime.title',
    },
  },
  safety: {
    title: 'safety.title',
    lagging: {
      title: 'safety.lagging.title',
    },
    total: {
      title: 'safety.total.title',
    },
    leading: {
      title: 'safety.leading.title',
    },
    tabs: {
      lagging: 'safety.tabs.lagging',
      total: 'safety.tabs.total',
      leading: 'safety.tabs.leading',
    },
    nonStoreLabel: 'safety.nonStoreLabel',
    store: {
      'Lagging Indicators': 'safety.store.Lagging Indicators',
      'Total Incidents': 'safety.store.Total Incidents',
      'Leading Indicators': 'safety.store.Leading Indicators',
    },
  },
  sales: {
    title: 'sales.title',
    tabs: {
      overview: 'sales.tabs.overview',
      details: 'sales.tabs.details',
      location: 'sales.tabs.location',
    },
    overview: {
      hourlySales: 'sales.overview.hourlySales',
      originated: 'sales.overview.originated',
      lineBusting: 'sales.overview.lineBusting',
      fulfilled: 'sales.overview.fulfilled',
      shipFromStore: 'sales.overview.shipFromStore',
      driveUp: 'sales.overview.driveUp',
      orderPickup: 'sales.overview.orderPickup',
      shipt: 'sales.overview.shipt',
      otherSales: 'sales.overview.otherSales',
      saveTheSale: 'sales.overview.saveTheSale',
      goal: 'sales.overview.goal',
      lastYear: 'sales.overview.lastYear',
      compPercent: 'sales.overview.compPercent',
      originatedCompPercent: 'sales.overview.originatedCompPercent',
      fulfilledCompPercent: 'sales.overview.fulfilledCompPercent',
      basketSize: 'sales.overview.basketSize',
      transactions: 'sales.overview.transactions',
      total: 'sales.overview.total',
      ly: 'sales.overview.ly',
      bpToLy: 'sales.overview.bpToLy',
      bpToLyPercent: 'sales.overview.bpToLyPercent',
    },
    details: {
      workarea: {
        title: 'sales.details.workarea.title',
        tabs: {
          sales: 'sales.details.workarea.tabs.sales',
          salesMix: 'sales.details.workarea.tabs.salesMix',
        },
      },
      merch: {
        title: 'sales.details.merch.title',
        tabs: {
          sales: 'sales.details.merch.tabs.sales',
          salesMix: 'sales.details.merch.tabs.salesMix',
        },
      },
    },
    location: {
      title: 'sales.location.title',
      tabs: {
        sales: 'sales.location.tabs.sales',
        forecast: 'sales.location.tabs.forecast',
        compPercent: 'sales.location.tabs.compPercent',
      },
    },
  },
  shortage: {
    title: 'shortage.title',
    totalShortage: 'shortage.totalShortage',
    lastInvetoryDate: 'shortage.lastInvetoryDate',
    nextScheduledInventoryDate: 'shortage.nextScheduledInventoryDate',
    'last inventory date': 'shortage.last inventory date',
    'next scheduled inventory date': 'shortage.next scheduled inventory date',
    thisYear: {
      title: 'shortage.thisYear.title',
    },
    merch: {
      title: 'shortage.merch.title',
    },
    top10: {
      title: 'shortage.top10.title',
    },
  },
  storeFacts: {
    title: 'storeFacts.title',
    quickFacts: 'storeFacts.quickFacts',
    lastYearSales: 'storeFacts.lastYearSales',
    originatedSales: 'storeFacts.originatedSales',
    fulfilledSales: 'storeFacts.fulfilledSales',
    totalSales: 'storeFacts.totalSales',
    hours: 'storeFacts.hours',
    keyDates: 'storeFacts.keyDates',
    opened: 'storeFacts.opened',
    lastRemodel: 'storeFacts.lastRemodel',
    packStation: 'storeFacts.packStation',
    leadership: 'storeFacts.leadership',
    store: 'storeFacts.store',
    role: 'storeFacts.role',
    name: 'storeFacts.name',
    since: 'storeFacts.since',
    capabilities: 'storeFacts.capabilities',
  },
  settings: {
    title: 'settings.title',
    info: 'settings.info',
    you: 'settings.you',
    location: 'settings.location',
    version: 'settings.version',
    help: 'settings.help',
    feedback: 'settings.feedback',
    language: 'settings.language',
    languages: {
      en: 'settings.languages.en',
      es: 'settings.languages.es',
      EN: 'settings.languages.EN',
      ES: 'settings.languages.ES',
    },
    creatingPreferences: 'settings.creatingPreferences',
    updatingPreferences: 'settings.updatingPreferences',
  },
  unauthorized: {
    title: 'unauthorized.title',
    message: 'unauthorized.message',
  },
  contact: {
    mainPhone: 'contact.mainPhone',
    fax: 'contact.fax',
  },
  location: {
    region: 'location.region',
    group: 'location.group',
    district: 'location.district',
    RDC: 'location.RDC',
    county: 'location.county',
  },
  timePeriods: {
    today: 'timePeriods.today',
    yesterday: 'timePeriods.yesterday',
    weekToDate: 'timePeriods.weekToDate',
    lastWeek: 'timePeriods.lastWeek',
    monthToDate: 'timePeriods.monthToDate',
    yearToDate: 'timePeriods.yearToDate',
  },
  filters: {
    compPercent: 'filters.compPercent',
    viewInPercent: 'filters.viewInPercent',
    viewInDollars: 'filters.viewInDollars',
  },
}
