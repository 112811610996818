export function getTodaysDateFormatted(): string {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export function getLocationFilter(location: string) {
  let params = {} as { [key: string]: string }

  if (location.startsWith('Company')) {
    return params
  } else {
    params.filter_value = location
    switch (location.charAt(0)) {
      case 'R':
        params.filter_dimension = 'regions'
        break
      case 'G':
        params.filter_dimension = 'groups'
        break
      case 'D':
        params.filter_dimension = 'districts'
        break
      case 'T':
        params.filter_dimension = 'store'
        break
      default:
        break
    }
    return params
  }
}
