import { Card, Grid, Divider } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { ShelfIcon } from '@enterprise-ui/icons'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

interface IShortageCard {
  dollars: string | undefined
  percent: string | undefined
}

export const ShortageCard = ({
  dollars = 'n/a',
  percent = 'n/a',
}: IShortageCard) => {
  const { shortage } = TRANSLATION_KEYS
  const { t } = useEssentials()
  return (
    <Card>
      <Grid.Container className="hc-ta-center hc-pt-sm" justify="center">
        <Grid.Item xs={12} className="hc-fs-lg">
          <EnterpriseIcon icon={ShelfIcon} size="xl" />
          <span className="hc-pl-sm">{t(shortage.totalShortage)}</span>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container
        className="hc-ta-center hc-pb-xl hc-pt-md"
        justify="center"
      >
        <Grid.Item xs={6} className="hc-fs-2x hc-pr-min">
          {dollars}
        </Grid.Item>
        <Divider direction="vertical"></Divider>
        <Grid.Item xs={6} className="hc-fs-2x hc-pl-min">
          {percent}
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}
