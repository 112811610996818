import { Grid } from '@enterprise-ui/canvas-ui-react'
import { ErrorAlert } from 'components/error-fallback'
import { GeneralizedComponent } from 'components/generalized-component/generalized-component'
import { BasicPlaceholder } from 'components/placeholder'
import { useTheftStore } from 'hooks/assets-protection/useTheftStore'
import useCountdown from 'hooks/utils/useCountdown'
import { useEssentials } from 'hooks/utils/useEssentials'
import { getLocationFilters } from 'utils/api'
import { Cell, TableData, TitledTableData } from 'utils/data'

export const TheftStore = () => {
  const { t, currentLocation, env, session, logout } = useEssentials()
  const filters = getLocationFilters(currentLocation)
  let { data, isLoading, isError, error } = useTheftStore({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    filters,
  })
  const count = useCountdown(5, logout, error as Error)

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }
  if (isError) {
    return <ErrorAlert error={error as Error} count={count} />
  }

  const pickColumns = (data: TableData, columnIndexes: number[]): TableData => {
    const newData = { ...data }
    newData.headings = columnIndexes
      .map((index) => data.headings?.[index])
      .filter((heading): heading is Cell => heading !== undefined)
    newData.rows = newData.rows.map((row) =>
      columnIndexes.map((index) => row[index]),
    )
    newData.footer = columnIndexes
      .map((index) => data.footer?.[index])
      .filter((heading): heading is Cell => heading !== undefined)

    return newData
  }

  return (
    <Grid.Container>
      <Grid.Item xs={12}>
        {data?.map((titledTable: TitledTableData) => {
          const { title, table } = titledTable

          let { headings } = table
          if (headings) {
            headings[3] = headings[1]
            headings[4] = headings[2]
          }

          const payload = {
            'view in %': [
              {
                title: '',
                subtitle: '',
                data: {
                  tab: pickColumns(table, [0, 1, 2]),
                },
              },
            ],
            'view in $': [
              {
                title: '',
                subtitle: '',
                data: {
                  tab: pickColumns(table, [0, 3, 4]),
                },
              },
            ],
          }

          return (
            <GeneralizedComponent
              payload={payload}
              title={t(title)}
              isLoading={isLoading}
              scrollableWidth={false}
              widths={[170, 90, 90]}
              key={title}
            />
          )
        })}
      </Grid.Item>
    </Grid.Container>
  )
}
