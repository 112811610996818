import { useEssentials } from '../../../hooks/utils/useEssentials'
import { useTranslation } from 'react-i18next'
import { useSalesHourly } from '../../../hooks/sales'
import { Constants } from '../../../constants/constants'
import {
  getGreenfieldFilterValues,
  getGreenfieldLocationFilter,
} from '../../../utils/greenfield'
import { format, isValid, parse } from 'date-fns'
import { Layout } from '../../../components/layout'
import { Container } from '../../../components/container/Container'
import { BasicPlaceholder } from '../../../components/placeholder'
import { ErrorAlert } from '../../../components/error-fallback'
import { Grid, Table } from '@enterprise-ui/canvas-ui-react'
import { buildDataTableObject } from '../../../utils/data'

export const HourlySalesPage = () => {
  const { currentLocation, env, session } = useEssentials()
  const { t } = useTranslation()

  const { data, isLoading, isError, error } = useSalesHourly({
    env,
    accessToken: session?.accessToken ?? '',
    dynamicSubPath: Constants.GREENFIELD_CARDS.SALES.HOURLY,
    filters: {
      ...getGreenfieldFilterValues(
        'SALES_DATE',
        format(new Date(), 'yyyy-MM-dd'),
      ),
      ...getGreenfieldLocationFilter(currentLocation),
    },
  })

  if (isLoading) {
    return (
      <Layout heading={t('hourly sales')}>
        <Container>
          <div className="placeholder-container" data-testid="placeholder">
            <BasicPlaceholder type="text" rows={1} />
            <BasicPlaceholder type="table" rows={8} columns={3} />
          </div>
        </Container>
      </Layout>
    )
  }

  if (isError) {
    return (
      <Layout heading={t('hourly sales')}>
        <Container>
          <ErrorAlert error={error as Error} />
        </Container>
      </Layout>
    )
  }
  for (const row of data.rows) {
    if (typeof row[0].value === 'string') {
      const parsedTime = parse(row[0].value, 'hh a', new Date())
      if (!isValid(parsedTime)) {
        return
      }
      const formattedTime = format(parsedTime, 'h:mma')
      row[0].value = formattedTime
    }
  }

  return (
    <Layout heading={t('hourly sales')}>
      <Container>
        <Grid.Container className="hc-mb-normal" justify="space-between">
          <Grid.Item xs={12}>
            <Table
              data={buildDataTableObject({
                currentData: data,
                widths: [80, 80, 80, 80],
              })}
              name={'Hourly Sales'}
            />
          </Grid.Item>
        </Grid.Container>
      </Container>
    </Layout>
  )
}
