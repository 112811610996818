import { useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Container } from 'components/container/Container'
import { useUser } from 'components/user-context'
import { UrlParams } from 'enums/url-params'
import { useSalesLocation } from 'hooks/sales/useSalesLocation'
import { useSearchParams } from 'react-router-dom'
import { useMPMEnv } from 'utils/env'
import { BasicPlaceholder } from 'components/placeholder'
import { ErrorAlert } from 'components/error-fallback'
import { addHyperlinkToFirstColumn, Cell, TableData } from 'utils/data'
import { useSalesLocationHistorical } from 'hooks/sales/useSalesLocationHistorical'
import { getGreenfieldDrillValues } from 'utils/greenfield'
import { GeneralizedComponent } from 'components/generalized-component/generalized-component'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { Constants } from 'constants/constants'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const SalesLocation = () => {
  const { t } = useEssentials()
  const {
    filters: filtersKeys,
    sales: salesKeys,
    timePeriods,
  } = TRANSLATION_KEYS
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { logout, session } = useAuth()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const [timePeriodIndex, setTimePeriodIndex] = useState<number>(0)
  const [tabsIndex, setTabsIndex] = useState<number>(0)

  const today = useSalesLocation({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath: Constants.GREENFIELD_CARDS.SALES.LOCATION_INTRADAY,
    filters: getGreenfieldDrillValues(currentLocation),
  })
  const result = useSalesLocationHistorical({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath: Constants.GREENFIELD_CARDS.SALES.LOCATION_HISTORICAL,
    filters: getGreenfieldDrillValues(currentLocation),
  })
  const results = [today, ...result]
  results.forEach((result) => {
    if (result.data) {
      result.data = addHyperlinkToFirstColumn(result.data, currentLocation)
    }
  })
  useRefresh(today.refetch, today.remove)
  const count = useCountdown(5, logout, today.error as Error)

  if (results.some((d) => d.isLoading)) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={10} columns={3} />
      </div>
    )
  }
  if (results.some((d) => d.isError)) {
    const firstError = results.find((d) => d.isError)
    return <ErrorAlert error={firstError.error} count={count} />
  }

  const pickColumns = (data: TableData, columnIndexes: number[]): TableData => {
    const newData = { ...data }
    newData.headings = columnIndexes
      .map((index) => data.headings?.[index])
      .filter((heading): heading is Cell => heading !== undefined)
    newData.rows = newData.rows.map((row) =>
      columnIndexes.map((index) => row[index]),
    )
    newData.footer = columnIndexes
      .map((index) => data.footer?.[index])
      .filter((heading): heading is Cell => heading !== undefined)

    return newData
  }

  const sales = t(salesKeys.location.tabs.sales)
  const forecast = t(salesKeys.location.tabs.forecast)
  const compPercent = t(salesKeys.location.tabs.compPercent)
  const payload = {
    [t(filtersKeys.viewInDollars)]: [
      {
        title: t(timePeriods.today),
        subtitle: '',
        data: {
          [sales]: pickColumns(today.data, [0, 1, 2, 3]),
          [forecast]: pickColumns(today.data, [0, 1, 4, 5]),
          [compPercent]: pickColumns(today.data, [0, 1, 6, 7]),
        },
      },
      {
        title: t(timePeriods.yesterday),
        subtitle: '',
        data: {
          [sales]: pickColumns(result[0].data, [0, 1, 2, 3]),
          [forecast]: pickColumns(result[0].data, [0, 1, 4, 5]),
          [compPercent]: pickColumns(result[0].data, [0, 1, 6, 7, 8]),
        },
      },
      {
        title: t(timePeriods.weekToDate),
        subtitle: '',
        data: {
          [sales]: pickColumns(result[1].data, [0, 1, 2, 3]),
          [forecast]: pickColumns(result[1].data, [0, 1, 4, 5]),
          [compPercent]: pickColumns(result[1].data, [0, 1, 6, 7, 8]),
        },
      },
      {
        title: t(timePeriods.lastWeek),
        subtitle: '',
        data: {
          [sales]: pickColumns(result[2].data, [0, 1, 2, 3]),
          [forecast]: pickColumns(result[2].data, [0, 1, 4, 5]),
          [compPercent]: pickColumns(result[2].data, [0, 1, 6, 7, 8]),
        },
      },
      {
        title: t(timePeriods.monthToDate),
        subtitle: '',
        data: {
          [sales]: pickColumns(result[3].data, [0, 1, 2, 3]),
          [forecast]: pickColumns(result[3].data, [0, 1, 4, 5]),
          [compPercent]: pickColumns(result[3].data, [0, 1, 6, 7, 8]),
        },
      },
      {
        title: t(timePeriods.yearToDate),
        subtitle: '',
        data: {
          [sales]: pickColumns(result[4].data, [0, 1, 2, 3]),
          [forecast]: pickColumns(result[4].data, [0, 1, 4, 5]),
          [compPercent]: pickColumns(result[4].data, [0, 1, 6, 7, 8]),
        },
      },
    ],
  }

  const handleClick = (timePeriodIndex: number, tabsIndex: number) => {
    setTimePeriodIndex(timePeriodIndex)
    setTabsIndex(tabsIndex)
  }

  return (
    <Container role="main">
      <GeneralizedComponent
        payload={payload}
        title={t(salesKeys.location.title)}
        scrollableWidth={false}
        sortable={false}
        widths={[70, 100, 120, 100, 100]}
        pinFirstColumn={false}
        parentHandleClick={handleClick}
        selectedTabIndex={tabsIndex}
        selectedTimeIndex={timePeriodIndex}
      />
    </Container>
  )
}
