import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchShortage } from 'queries/shortage/shortage'
import { ShortageFetchProps } from 'types/api'
import { ITitleListResponse } from 'utils/api'
import { TIME } from 'utils/misc'
import { useAnalytics } from '@praxis/component-analytics'

export function useShortage({
  env,
  accessToken,
  location,
  filters,
}: ShortageFetchProps): ITitleListResponse {
  const { trackEvent } = useAnalytics()
  const { tenMinutes } = TIME
  const { data, isLoading, isError, refetch, remove, error } = useQuery(
    [QueryKeys.SHORTAGE, location],
    () =>
      fetchShortage({
        env,
        accessToken,
        location,
        filters,
        trackEvent,
      }),
    {
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
      retry: false,
    },
  )

  return { data, isLoading, isError, refetch, remove, error }
}
