import { useEffect, useMemo } from 'react'
import {
  Badge,
  Breadcrumbs,
  Button,
  Heading,
  SideNav,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  MenuIcon,
  SearchIcon,
  BellIcon,
  GearIcon,
  LogoutIcon,
  NotebookIcon,
  StoreIcon,
  ExternalIcon,
} from '@enterprise-ui/icons'
import { useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useAnalytics } from '@praxis/component-analytics'
import './layout.css'
import { isStore as isStoreCheck } from 'utils/location'
import { useUser } from 'components/user-context/user-context'
import { useFlags } from '../../firebase/FirebaseRemoteConfig.provider'
import { UrlParams } from 'enums/url-params'
import { getBreadcrumbs, getHeaderLocation } from './helpers'
import { useLocationHierarchy } from 'hooks/locationHierarchy/useLocationHierarchy'
import { useMPMEnv } from 'utils/env'
import usePullDown from 'hooks/utils/usePullDown'
import { messages } from './messages'

export interface ILayoutProps {
  heading: string
  children?: JSX.Element | JSX.Element[]
  isBottomNavDisplayed?: boolean
  topNavRightButton?: JSX.Element | null
  className?: string
}

export const Layout = ({
  heading,
  children,
  topNavRightButton,
  className,
}: ILayoutProps) => {
  const {
    greenfieldLinkEnabled,
    settingsLinkEnabled,
    salesLinkEnabled,
    assetsProtectionEnabled,
    brandsEnabled,
    loyaltyEnabled,
    notesEnabled,
    opsMetricsEnabled,
    storeFulfillmentEnabled,
    storeInfoEnabled,
    trailerEnabled,
  } = useFlags()

  const [params] = useSearchParams()
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  const { logout } = useAuth()
  const navigate = useNavigate()
  const navigateToScreen = (route: string) => () => navigate(route)
  const { trackEvent } = useAnalytics()
  const { greenfieldGroups: userPermissions, location } = useUser()
  const env = useMPMEnv()
  const { session } = useAuth()
  const currentURL = window.location.pathname
  let currentLocation = params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const isStore = isStoreCheck(currentLocation)

  const { data: locationData } = useLocationHierarchy({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    enabled: true,
  })

  const locationName = locationData?.location_hierarchy?.location_name ?? ''
  const currentHeading = isStore
    ? `${locationName} ${currentLocation}`
    : getHeaderLocation(currentLocation)

  const isAuthorized = userPermissions?.some((str) => str === 'MPM')

  const childrenContainerClasses = className
    ? `main-content-container ${className}`
    : 'main-content-container'

  const unreadCount = 0

  const alertsButton = (
    <Button
      iconOnly
      className="navbar-menu-button navbar-alerts"
      aria-label="View alerts"
      type="ghost"
      onClick={navigateToScreen('/alerts')}
    >
      <EnterpriseIcon icon={BellIcon} size="xl" className="navbar-icon" />
      {unreadCount > 0 ? (
        <Badge
          aria-label={`You have ${unreadCount} unread notifications`}
          content="5"
          location="inline"
          className="unread-count"
        />
      ) : null}
    </Button>
  )

  const locationSearchButton = (
    <Button
      iconOnly
      className="navbar-menu-button"
      aria-label={messages.searchAriaLabel}
      type="ghost"
      onClick={() =>
        navigate('/location-search', {
          state: { previousLocationPathname: currentURL },
        })
      }
    >
      <EnterpriseIcon icon={SearchIcon} size="xl" className="navbar-icon" />
    </Button>
  )

  let breadcrumbList: {
    label: string
    link: string
  }[] = useMemo(() => [], [])
  let breadcrumbHTML: React.ReactNode[] = []
  if (locationData?.location_hierarchy) {
    breadcrumbList = getBreadcrumbs({
      location,
      currentLocation,
      locationData: locationData,
    })
    breadcrumbHTML = breadcrumbList.map((item, index) => {
      if (index === breadcrumbList.length - 1) {
        return (
          <Breadcrumbs.Item className="current-breadcrumb" key={item.label}>
            {item.label}
          </Breadcrumbs.Item>
        )
      }
      return (
        <Breadcrumbs.Item key={item.label} as={'span'}>
          <Link to={item.link}>{item.label}</Link>
        </Breadcrumbs.Item>
      )
    })
  }

  useEffect(() => {
    const calculateOffset = () => {
      const navbarContainer = document.querySelector(
        '.navbar-container',
      ) as HTMLElement
      const tabsContainer = document.querySelector('.C-Tabs') as HTMLElement
      const mainContainer = document.querySelector(
        '.main-content-container',
      ) as HTMLElement

      let totalOffset = navbarContainer.offsetHeight
      if (tabsContainer) {
        tabsContainer.style.top = navbarContainer.offsetHeight + 'px'
        totalOffset += tabsContainer.offsetHeight
      }
      mainContainer.style.top = totalOffset + 'px'
    }
    calculateOffset()
  }, [location, currentLocation, breadcrumbList])

  const hasTabs = document.querySelector('.C-Tabs') as HTMLElement
  const hasBreadcrumbs = breadcrumbHTML.length > 0
  const adjusted = hasBreadcrumbs && hasTabs
  const adjustedClass = 'adjust-pb'

  const { handleTouchStart, handleTouchMove, handleTouchEnd } = usePullDown()

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={`navbar-container ${adjusted ? adjustedClass : ''}`}>
        <Button
          iconOnly
          className="navbar-menu-button"
          aria-label={messages.navMenuAriaLabel}
          onClick={() => setIsSideNavOpen(true)}
          type="ghost"
        >
          <EnterpriseIcon icon={MenuIcon} size="xl" className="navbar-icon" />
        </Button>
        <div className="navbar-heading">
          <Heading className="kpi-heading hc-fs-xl" tabIndex={0}>
            {heading}
          </Heading>
          <Heading
            className="location-heading hc-fs-xs hc-lh-normal"
            tabIndex={0}
          >
            {currentHeading}
          </Heading>
        </div>
        <div className="navbar-container-right">
          {!topNavRightButton ? (
            <>
              {alertsButton}
              {locationSearchButton}
            </>
          ) : (
            topNavRightButton
          )}
        </div>
        {breadcrumbHTML.length > 0 && (
          <Breadcrumbs className="breadcrumbs-container">
            {breadcrumbHTML}
          </Breadcrumbs>
        )}
      </div>
      <SideNav
        aria-hidden={!isSideNavOpen}
        suppressAutoOpen
        hasOverlay
        isVisible={isSideNavOpen}
        onRequestClose={() => setIsSideNavOpen(false)}
      >
        {isAuthorized && isSideNavOpen && (
          <>
            <SideNav.Header>
              <Heading size={5}>{messages.appTitle}</Heading>
            </SideNav.Header>
            <SideNav.Navigation>
              <SideNav.NavigationItem onClick={navigateToScreen('/shortage')}>
                {messages.shortageLinkTitle}
              </SideNav.NavigationItem>
              {salesLinkEnabled && (
                <SideNav.NavigationItem
                  aria-hidden={!salesLinkEnabled}
                  onClick={navigateToScreen('/sales')}
                >
                  {messages.salesLinkTitle}
                </SideNav.NavigationItem>
              )}
              {brandsEnabled && (
                <SideNav.NavigationItem aria-hidden={!brandsEnabled}>
                  {messages.brandsLinkTitle}
                </SideNav.NavigationItem>
              )}
              <SideNav.NavigationItem onClick={navigateToScreen('/safety')}>
                {messages.safetyLinkTitle}
              </SideNav.NavigationItem>
              {storeFulfillmentEnabled && (
                <SideNav.NavigationItem aria-hidden={!storeFulfillmentEnabled}>
                  {messages.storeFulfillmentLinkTitle}
                </SideNav.NavigationItem>
              )}
              <SideNav.NavigationItem onClick={navigateToScreen('/payroll')}>
                {messages.payrollLinkTitle}
              </SideNav.NavigationItem>
              {loyaltyEnabled && (
                <SideNav.NavigationItem aria-hidden={!loyaltyEnabled}>
                  {messages.loyaltyLinkTitle}
                </SideNav.NavigationItem>
              )}
              {opsMetricsEnabled && (
                <SideNav.NavigationItem aria-hidden={!opsMetricsEnabled}>
                  {messages.operationsLinkTitle}
                </SideNav.NavigationItem>
              )}
              {trailerEnabled && (
                <SideNav.NavigationItem aria-hidden={!trailerEnabled}>
                  {messages.trailerLinkTitle}
                </SideNav.NavigationItem>
              )}
              {assetsProtectionEnabled && (
                <SideNav.NavigationItem
                  aria-hidden={!assetsProtectionEnabled}
                  onClick={navigateToScreen('/assets-protection')}
                >
                  {messages.assetsProtection}
                </SideNav.NavigationItem>
              )}
            </SideNav.Navigation>
          </>
        )}
        {isSideNavOpen && (
          <SideNav.Footer>
            <SideNav.Navigation>
              {isStore ? (
                <>
                  {notesEnabled && (
                    <SideNav.NavigationItem
                      aria-hidden={!notesEnabled}
                      onClick={navigateToScreen('/notes')}
                    >
                      <EnterpriseIcon icon={NotebookIcon} />{' '}
                      {messages.notesLinkTitle}
                    </SideNav.NavigationItem>
                  )}
                  {storeInfoEnabled && (
                    <SideNav.NavigationItem
                      aria-hidden={!storeInfoEnabled}
                      onClick={navigateToScreen('/store-facts')}
                    >
                      <EnterpriseIcon icon={StoreIcon} />{' '}
                      {messages.storeFactsLinkTitle}
                    </SideNav.NavigationItem>
                  )}
                </>
              ) : null}
              {greenfieldLinkEnabled && (
                <SideNav.NavigationItem
                  aria-hidden={!greenfieldLinkEnabled}
                  as={'a'}
                  href="https://greenfield.target.com"
                  role="link"
                >
                  <EnterpriseIcon icon={ExternalIcon} />{' '}
                  {messages.greenfieldLinkTitle}
                </SideNav.NavigationItem>
              )}
              {settingsLinkEnabled && (
                <SideNav.NavigationItem
                  aria-hidden={!settingsLinkEnabled}
                  onClick={navigateToScreen('/settings')}
                >
                  <EnterpriseIcon icon={GearIcon} />{' '}
                  {messages.settingsLinkTitle}
                </SideNav.NavigationItem>
              )}
              <SideNav.NavigationItem
                onClick={() => {
                  trackEvent({
                    view: {
                      name: 'logout',
                    },
                    event: {
                      type: 'click',
                    },
                  })
                  logout()
                }}
              >
                <EnterpriseIcon icon={LogoutIcon} /> {messages.logOutCTA}
              </SideNav.NavigationItem>
            </SideNav.Navigation>
          </SideNav.Footer>
        )}
      </SideNav>
      <div className={childrenContainerClasses}>{children}</div>
    </div>
  )
}
