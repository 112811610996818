import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchShortageTopTen } from 'queries/shortage/shortage'
import { ShortageFetchProps } from 'types/api'
import { IResponse, apiNotEnabled } from 'utils/api'
import { TIME } from 'utils/misc'
import { useAnalytics } from '@praxis/component-analytics'

export function useShortageTopTen({
  env,
  accessToken,
  location,
  dynamicSubPath,
  filters,
}: ShortageFetchProps): IResponse {
  const { trackEvent } = useAnalytics()
  const { tenMinutes } = TIME
  /* Needs to always run because it's used for the Flex Hours section on Payroll Monthly */
  const enabled = (dynamicSubPath && dynamicSubPath?.length > 0) || false
  const { data, isLoading, isError, refetch, remove, error } = useQuery(
    [QueryKeys.SHORTAGE_TOP_TEN, location, dynamicSubPath],
    () =>
      fetchShortageTopTen({
        env,
        accessToken,
        location,
        dynamicSubPath,
        filters,
        trackEvent,
      }),
    {
      enabled,
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
    },
  )

  if (!enabled) {
    return apiNotEnabled()
  }

  return { data, isLoading, isError, refetch, remove, error }
}
