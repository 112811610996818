import { ITranslationKeys } from '../ITranslationKeys'

export const es: ITranslationKeys = {
  alerts: 'Alertas',
  alert: {
    heading: '¡Ups! Parece que hay un problema.',
    generic:
      'Por favor, actualiza la página o contacta con soporte si el problema persiste. ¡Gracias por tu paciencia!',
    expiredSession: (count: number = 0) =>
      `Parece que tu sesión ha expirado. Redirigiendo en ${count} segundos...`,
    retry: 'Reintentar',
  },
  assetsProtection: {
    title: 'assets protection',
    tabs: {
      payroll: 'nómina',
      theft: 'robo',
      secure: 'seguro',
    },
  },
  locationSearch: {
    header: 'select location',
    searchLabel: 'search region, group, district, or store',
    matchNotFound: 'no location matched your search. showing nearby locations.',
    nearbyLocationsHeader: 'nearby locations',
  },
  payroll: {
    title: 'nómina',
    tabs: {
      location: 'ubicación',
      workgroup: 'grupo de trabajo',
      overtime: 'horas extra',
      monthly: 'mensualmente',
    },
    location: {
      title: 'nómina por ubicación',
      payrollByLocation: 'nómina por ubicación',
      payrollByWeek: 'nómina por semana',
      projFlexHours: 'horas flexibles proyectadas',
      guranteedFlexHours: 'horas flexibles garantizadas',
      bpFlextoplan: 'm/(p) flex para planificar',
    },
    workgroup: {
      title: 'horas planificadas por grupo de trabajo',
    },
    overtime: {
      title: '% de horas extra semanales de las horas totales - tendencia',
    },
  },
  safety: {
    title: 'seguridad',
    lagging: {
      title: 'lagging safety',
    },
    total: {
      title: 'total safety',
    },
    leading: {
      title: 'leading safety',
    },
    tabs: {
      lagging: 'rezagados',
      total: 'total',
      leading: 'leading',
    },
    nonStoreLabel:
      'Los datos se actualizan el miércoles y son válidos hasta el sábado',
    store: {
      'Lagging Indicators': 'indicadores rezagados',
      'Total Incidents': 'total incidents',
      'Leading Indicators': 'leading indicators',
    },
  },
  sales: {
    title: 'ventas',
    tabs: {
      overview: 'general',
      details: 'details',
      location: 'ubicación',
    },
    overview: {
      hourlySales: 'ventas por hora',
      originated: 'originadas',
      lineBusting: 'de cobro móvil',
      fulfilled: 'de Fulfillment',
      shipFromStore: 'ship from store',
      driveUp: 'drive up',
      orderPickup: 'order pickup',
      shipt: 'shipt',
      otherSales: 'other sales',
      saveTheSale: 'save the sale',
      goal: 'meta',
      lastYear: 'last year',
      compPercent: '% comp',
      originatedCompPercent: '% comparativo de originadas',
      fulfilledCompPercent: '% comparativo de Fulfillment',
      basketSize: 'tamaño de la canasta',
      transactions: 'transacciones',
      total: 'total',
      ly: 'año pasado',
      bpToLy: 'b/p to ly',
      bpToLyPercent: 'b/p to ly %',
    },
    details: {
      workarea: {
        title: 'área de trabajo',
        tabs: {
          sales: 'sales',
          salesMix: 'sales mix',
        },
      },
      merch: {
        title: 'ventas por mercancía',
        tabs: {
          sales: 'sales',
          salesMix: 'sales mix',
        },
      },
    },
    location: {
      title: 'ventas por ubicación',
      tabs: {
        sales: 'ventas',
        forecast: 'pronóstico',
        compPercent: '% comp',
      },
    },
  },
  shortage: {
    title: 'descuadre de inventario',
    totalShortage: 'descuadre total',
    lastInvetoryDate: 'última fecha de inventario',
    nextScheduledInventoryDate: 'fecha de siguiente inventario programado',
    'last inventory date': 'última fecha de inventario',
    'next scheduled inventory date': 'fecha de siguiente inventario programado',
    thisYear: {
      title: 'este año',
    },
    merch: {
      title: 'descuadre de inventario',
    },
    top10: {
      title: '10 descuadres principales',
    },
  },
  storeFacts: {
    title: 'store facts',
    quickFacts: 'datos sobre la tienda',
    lastYearSales: 'ventas del año pasado',
    originatedSales: 'ventas originadas',
    fulfilledSales: 'ventas de Fulfillment',
    totalSales: 'ventas totales',
    hours: 'horas',
    keyDates: 'key dates',
    opened: 'abierto',
    lastRemodel: 'última remodelación',
    packStation: 'estaciones de empaque',
    leadership: 'liderazgo',
    store: 'tienda',
    role: 'role',
    name: 'name',
    since: 'since',
    capabilities: 'capacidades',
  },
  settings: {
    title: 'configuración',
    info: 'información',
    you: 'usted',
    location: 'Ubicación',
    version: 'versión',
    help: 'ayuda',
    feedback: 'opiniones',
    language: 'idioma',
    languages: {
      en: 'en',
      es: 'es',
      EN: 'EN',
      ES: 'ES',
    },
    creatingPreferences: 'creando preferencias de usuario',
    updatingPreferences: 'actualizando preferencias de usuario',
  },
  unauthorized: {
    title: 'Unauthorized',
    message:
      'You do not have access to view details inside MPM. If you need access reach out to myPerformance.support@target.com.',
  },
  contact: {
    mainPhone: 'main phone',
    fax: 'fax',
  },
  location: {
    region: 'región',
    group: 'grupo',
    district: 'distrito',
    RDC: 'RDC',
    county: 'County',
  },
  timePeriods: {
    today: 'hoy',
    yesterday: 'ayer',
    weekToDate: 'semana a la fecha',
    lastWeek: 'última semana',
    monthToDate: 'del mes a la fecha',
    yearToDate: 'del año a la fecha',
  },
  filters: {
    compPercent: '% comp',
    viewInPercent: 'ver en %',
    viewInDollars: 'ver en $',
  },
}
