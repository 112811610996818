import { useEffect, useState } from 'react'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { getTableFormat } from 'utils/table'
import { Container } from 'components/container/Container'
import { usePayrollOvertime } from 'hooks/payroll/overtime'
import { BasicPlaceholder } from 'components/placeholder'
import { buildDataTableObject } from 'utils/data'
import useRefresh from 'hooks/utils/useRefresh'
import { getLocationFilters } from 'utils/api'
import { ErrorAlert } from 'components/error-fallback'
import useCountdown from 'hooks/utils/useCountdown'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const Overtime = () => {
  const { t, currentLocation, env, logout, session } = useEssentials()
  const { payroll } = TRANSLATION_KEYS

  const [tableObj, setTableObj] = useState({})
  const filters = getLocationFilters(currentLocation)
  const { data, isLoading, isError, refetch, remove, error } =
    usePayrollOvertime({
      env,
      accessToken: session?.accessToken ?? '',
      location: currentLocation,
      filters,
    })
  useRefresh(refetch, remove)
  const count = useCountdown(5, logout, error as Error)

  useEffect(() => {
    const widths = [110, ...getTableFormat(data?.rows?.length ?? 20, 100)]
    if (data) {
      const obj = buildDataTableObject({
        currentData: data,
        widths,
        pinFirstColumn: true,
      })
      setTableObj(obj)
    }
  }, [data])

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="text" rows={1} />
        <BasicPlaceholder type="table" rows={5} columns={3} />
      </div>
    )
  }

  if (isError || !data) {
    return <ErrorAlert error={error as Error} count={count} />
  }

  return (
    <Container>
      <p className="hc-fs-md">{t(payroll.overtime.title)}</p>
      <Table data={tableObj} scrollableWidth={true} />
    </Container>
  )
}
